import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import BreakpointUp from "../../components/Media/BreakpointUp";
import BreakpointDown from "../../components/Media/BreakpointDown";
import BannerBg from "../../images/banner-bg.png";
import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton";
import SecondaryLinkButton from "../../components/Button/SecondaryLinkButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import DottedCiriclebottomLeftImg from "../../images/dotted-bottom-left.svg";
import DottedCiricleTopRightImg from "../../images/dotted-top-right.svg";
import {
  ArrowLineIcon,
  DottedLine,
  BudgetIcon,
  CloseIcon,
} from "../../components/Icons";
import ScrollDown from "../../components/Button/Scroll-down";
import OfferBg from "../../images/offer-bg.jpg";

const Container = styled.div`
  position: relative;
  max-width: 1410px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;
const SectionWrapper = styled.div`
  padding-top: 120px;
  padding-bottom: 80px;
  background-image: inherit;
  background-color: #ebf0ff;
  background-size: inherit;
  text-align: left;
  border-bottom: 1px solid transparent;
  border-radius: 0 0 45px 45px;
  position: relative;
  ${BreakpointDown.xl`
		padding-top:40px;
		padding-bottom:80px;
`}
  ${BreakpointDown.sm`
			margin-left:-15px;
			margin-right:-15px;
			padding-left:15px;
			padding-right:15px;
			padding-bottom:	15px;
`}
&:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    background-image: url(${(props) => props.bannerBG});
    background-size: 66%;
    background-position: left 80%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    left: 0;
  }
`;

const BannerContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 45px;
  padding-right: 44px;
  ${BreakpointDown.xl`
		padding-left:30px;
		padding-right:30px;
`}
  ${BreakpointDown.lg`
		flex-direction: column;
		text-align:center;
		padding-left:0px;
		padding-right:0px;
`}
`;

const BannerContent = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  align-self: flex-end;
  margin-bottom: -20px;
  ${BreakpointUp.lg`
   max-width:52%;
`}
`;
const BannerContentImg = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 45px;
  max-width: 550px;
  ${BreakpointUp.lg`
	max-width:48%;
`}
  ${BreakpointDown.lg`
	 margin-top:50px;
	 padding-right:15px;
	`}

	${BreakpointDown.sm`
		 padding-left:15px;
		 margin-top:20px;
		 padding-left:15px;
	`}
`;
const BannerImgFigure = styled.div`
  position: relative;
  border: 8px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  &:before {
    content: "";
    display: block;
    width: 200px;
    height: 100px;
    position: absolute;
    left: -98px;
    bottom: -150px;
    z-index: 1;
    background-image: url(${DottedCiriclebottomLeftImg});
    background-repeat: no-repeat;
    ${BreakpointDown.xl`
		  display:none;  
		`}
  }
  &:after {
    content: "";
    display: block;
    width: 200px;
    height: 227px;
    position: absolute;
    right: -49px;
    top: -43px;
    z-index: 1;
    background-image: url(${DottedCiricleTopRightImg});
    background-repeat: no-repeat;
    ${BreakpointDown.xl`
		  display:none;  
		`}
  }
`;

const HeroBuildingImg = styled.div`
  position: absolute;
  border: 4px solid #fff;
  border-radius: 5px;
  left: 0;
  z-index: 1;
  max-width: 285px;
  width: 100%;
  bottom: -18px;
  margin-left: -66px;
  margin-bottom: -97px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  @media (max-width: 1279px) {
    max-width: 200px;
    bottom: 0px;
  }
  ${BreakpointDown.lg`
 left:43px;
`}
  ${BreakpointDown.md`
  max-width: 150px;
`}
${BreakpointDown.sm`
	max-width: 150px;
	left: 65px;
	bottom:50px;
`}
`;

const HeroBuildingImg1 = styled.div`
  position: absolute;
  border: 4px solid #fff;
  border-radius: 5px;
  right: 10px;
  z-index: 3;
  max-width: 237px;
  width: 100%;
  bottom: -46px;
  margin-left: -66px;
  margin-bottom: -97px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  @media (max-width: 1279px) {
    max-width: 180px;
    bottom: 0px;
  }
  ${BreakpointDown.lg`

`}
  ${BreakpointDown.md`
  max-width: 150px;
`}
${BreakpointDown.sm`
	max-width: 150px;
	bottom:50px;
	right: 0px;
`}
`;

const HeroBuildingImg2 = styled.div`
  position: absolute;
  border: 4px solid #fff;
  border-radius: 5px;
  right: -43px;
  top: 42%;
  transform: translateY(-50%);
  z-index: 3;
  max-width: 192px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  @media (max-width: 1279px) {
    max-width: 160px;
  }
  ${BreakpointDown.lg`
 
`}
  ${BreakpointDown.md`
  max-width: 150px;
`}
${BreakpointDown.sm`
 display:none;
`}
`;

const DottedLinWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 600px;
  width: 100%;
  height: 100%;
  z-index: 2;
  @media (max-width: 1440px) {
    max-width: 500px;
  }
  @media (max-width: 1366px) {
    max-width: 400px;
    top: auto;
    bottom: 40px;
  }
  ${BreakpointDown.xl`
	display:none;
`}
  svg {
    display: flex;
    margin-left: auto;
    max-width: 100%;
    @media (max-width: 1440px) {
      svg {
        width: 500px;
        height: 500px;
      }
    }
  }
`;
const BudgetIconWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  left: 47%;
  top: -50px;
  background: #fff;
  width: 100%;
  max-width: 73px;
  height: 73px;
  padding: 6px;
  z-index: 2;
  border-radius: 5px;
  ${BreakpointDown.sm`
	 margin-right:0px;
	 max-width:60px;
	`}
  svg {
    display: flex;
    margin-left: auto;
    width: 66px;
    height: 66px;
  }
  ${BreakpointDown.md`
	max-width: 50px;
	height: 50px;
	svg{
	width: 40px;
	height: 40px;
	max-width:100%;
	}
`}
  ${BreakpointDown.sm`
 display:none;
`}
`;
const SubHeading = styled.div`
  display: block;
  font-weight: 400;
  color: #121316;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: 0.28px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  @media (min-width: 1440px) {
    font-size: 34px;
  }
  ${BreakpointDown.xl`
		 font-size:24px;
		 line-height:20px;
		 margin-bottom:8px;
	 `}
  ${BreakpointDown.sm`
		 font-size:18px;
		 margin-bottom:0px;
	 `}
`;
const ImageCaption = styled.div`
  padding: 120px 0 150px;
  position: relative;
  @media (max-width: 1200px) {
    padding: 80px 0 80px;
  }
  @media (max-width: 1024px) {
    padding: 40px 0 60px;
  }
  & h1 {
    margin-bottom: 15px;
    text-align: -left;
    text-transform: capitalize;
    font-weight: 600;

    @media (min-width: 1440px) {
      font-size: 50px;
      line-height: 70px;
    }
    ${BreakpointDown.lg`
		 max-width:100%;
		 line-height: 40px;
		`}
  }
`;
const Desc = styled.div`
  display: block;
  color: #000;
  max-width: 800px;
  width: 100%;
  margin: 0 auto 30px auto;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 30px;
  padding: 10px 0;
  .pagebtn {
    &:first-child {
      margin-right: 10px;
      span {
        padding-left: 0;
        text-decoration: underline;
      }
    }
  }
  ${BreakpointUp.md`
		font-size: 26px;
		line-height: 32px;
		padding: 10px 0px;
	`}
`;
const DesktopHero = styled.div`
  /* display:none; */
  position: relative;
  z-index: 2;
  ${BreakpointUp.md`	    
		display:block;
	`}
`;
const OfferWrapper = styled.div`
  background: url(${(props) => props.offerBGImg});
  background-size: cover;
  background-position: center center;
  max-width: 1115px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  position: relative;
  padding: 11px 30px;
  border-radius: 0 0 4px 4px;
`;
const Offer = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  ${BreakpointDown.md`
	flex-direction: column-reverse;
	`}
`;
const OffterItem = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  .pagebtn {
    .btn {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  ${BreakpointDown.md`
		width:100%;
		max-width:100%;
		text-align: center;
		`}
  ${BreakpointDown.sm`
			font-size:16px;	
			line-height:24px;
		`}
`;
const MewYearImg = styled.div`
  position: relative;
  max-width: 320px;
  margin-left: auto;
  ${BreakpointDown.lg`
   	max-width:216px;
		`}
  ${BreakpointDown.md`
    margin-left:auto;
    margin-right:auto;
		`}

	&:before {
    content: "";
    width: 150px;
    height: 2px;
    display: inline-block;
    position: absolute;
    right: 100%;
    top: 50%;
    margin-top: -1px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.54) 38%,
      rgba(171, 124, 124, 0) 0%
    );
    background-position: top;
    background-size: 14px 1.4px;
    background-repeat: repeat-x;
    ${BreakpointDown.lg`
		 display:none;
		`}
  }
`;
const CloseBtn = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
  max-width: 12px;
  cursor: pointer;
  svg,
  path {
    width: 100%;
    fill: #fff;
  }
`;

const HeroSection = ({ adData, bannerData }) => {
  const [Offers, setOffers] = useState(true)
  const HandleOffer = () => setOffers(false)

  console.log(adData.backgroundImage.fluid.src != null
    ? adData.backgroundImage.fluid.src
    : OfferBg)

  return (
    <Container>
      <ScrollDown title="Scroll Down" />
      <SectionWrapper
        bannerBG={
          bannerData.backgroungImage.fluid.src != null
            ? bannerData.backgroungImage.fluid.src
            : BannerBg
        }
      >
        {Offers && <OfferWrapper
          offerBGImg={
            adData.backgroundImage.fluid.src != null
              ? adData.backgroundImage.fluid.src
              : OfferBg
          }
        >
          <Offer>
            <OffterItem>
              {adData.headingText}
              <Link to={adData.linkUrl} className="pagebtn">
                <SecondaryLinkButton
                  icon={<ArrowLineIcon />}
                  text={adData.linkText}
                />
              </Link>
            </OffterItem>
            <OffterItem>
              <MewYearImg>
                <Img fluid={adData.image.fluid} alt={adData.image.title} />
              </MewYearImg>
            </OffterItem>
            <CloseBtn onClick={HandleOffer}>
              <CloseIcon />
            </CloseBtn>
          </Offer>
        </OfferWrapper>
        }
        <ImageCaption>
          <BannerContentWrapper>
            <BannerContent>
              <SubHeading>{bannerData.subHeadingText}</SubHeading>
              <h1>{bannerData.headingText}</h1>
              <Desc>
                <Link to={bannerData.linkUrl} className="pagebtn">
                  <PrimaryLinkButton
                    icon={<ArrowLineIcon />}
                    text={bannerData.linkText}
                  />
                </Link>
                <Link to={bannerData.buttonUrl} className="pagebtn">
                  <SecondaryButton
                    icon={<ArrowLineIcon />}
                    text={bannerData.buttonText}
                  />
                </Link>
              </Desc>
            </BannerContent>
            <BannerContentImg>
              <BannerImgFigure>
                <DesktopHero>
                  <HeroBuildingImg>
                    <Img
                      fluid={bannerData.smallImages[0].fluid}
                      alt={bannerData.smallImages[0].title }
                    />
                  </HeroBuildingImg>
                  <HeroBuildingImg1>
                    <Img
                      fluid={bannerData.smallImages[1].fluid}
                      alt={bannerData.smallImages[1].title}
                    />
                  </HeroBuildingImg1>
                  <HeroBuildingImg2>
                    <Img
                      fluid={bannerData.smallImages[2].fluid}
                      alt={bannerData.smallImages[2].title}
                    />
                  </HeroBuildingImg2>
                  <DottedLinWrapper>
                    <DottedLine />
                  </DottedLinWrapper>
                  <BudgetIconWrapper>
                    <BudgetIcon />
                  </BudgetIconWrapper>
                  <Img
                    fluid={bannerData.mainImage.fluid}
                    alt={bannerData.mainImage.title}
                  />
                </DesktopHero>
              </BannerImgFigure>
            </BannerContentImg>
          </BannerContentWrapper>
          {/* <MobileHero>
            <Image name="mobile-hero.png" alt="commercial" />
          </MobileHero> */}
        </ImageCaption>
      </SectionWrapper>
    </Container>
  );
}

export default HeroSection;
