import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../sections/Home/HeroSection"
import { GradientWrapper } from "../components/Section";
import BgAfter from "../images/pattenr-right.png";
import BgBefore from "../images/bg-left.png";
import styled from "styled-components";
import { Section } from "../components/Section";

import Loadable from "@loadable/component";

const RentToOwn = Loadable(() => import("../sections/Home/RenttoOwn"));
const OurPrograms = Loadable(() => import("../sections/Home/OurPrograms"));
const Congratulation = Loadable(() => import("../components/Congratulations"));
const PaymentCalulator = Loadable(() =>
  import("../components/PaymentCalculator")
);
const RentalProgram = Loadable(() => import("../sections/Home/RentalProgram"));
const EasyOptionToPay = Loadable(() =>
  import("../sections/Home/EasyOptionToPay")
);
const ContractManagement = Loadable(() =>
  import("../sections/Home/ContractManagement")
);
const LoanApproval = Loadable(() => import("../sections/Home/LoanApproval"));

const SectionBorder = styled(Section)`
  border: 1px solid #cbd1e2;
`;

function IndexPage(queryData) {
  let pageData = queryData.data.contentfulHomePage;
  let adData = queryData.data.contentfulAdBanner;

  let calculatorData =
    queryData.data.allContentfulMonthlyCalculatorValues.nodes[0];
  return (
    <Layout pageName="home">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <GradientWrapper>
        <HeroSection adData={adData} bannerData={pageData.heroBanner} />
        <RentToOwn whoWeData={pageData.whoWeAreSection} />
      </GradientWrapper>
      <OurPrograms
        rtoProgramData={pageData.rtoProgramSection}
        rtoBenefitData={pageData.rtoBenefitSection}
        rtoCriteriaData={pageData.rtoCriteriaSection}
        ctaSectionData={pageData.ctaSection}
        rentalPartnerData={pageData.rentalPartnerSection}
      />
      <Congratulation barnBonusData={pageData.barnBonusSection} />
      <SectionBorder
        bgColor="#ebf0ff"
        pt="60px"
        pb="90px"
        xpt="40px"
        xpb="40px"
        bgBefore={`url(${BgBefore})`}
        bgAfter={`url(${BgAfter})`}
      >
        <PaymentCalulator calculatorData={calculatorData} />
      </SectionBorder>
      <GradientWrapper>
        <RentalProgram dealerSectionData={pageData.dealerSection} />
        <EasyOptionToPay paymentOptionData={pageData.paymentOptionSection} />
      </GradientWrapper>
      <ContractManagement jagBarnSectionData={pageData.jagBarnSection} />
      <LoanApproval quickContactSectionData={pageData.quickContactSection} />
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    contentfulHomePage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroBanner {
        subHeadingText
        headingText
        linkText
        linkUrl
        buttonText
        buttonUrl
        backgroungImage {
          title
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        mainImage {
          title
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        smallImages {
          title
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      whoWeAreSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
      }
      rtoProgramSection {
        labelText
        buttonUrl
        buttonText
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
      }
      rtoBenefitSection {
        headingText
        childrenContentfulSectionFeatureDetailsJsonNode {
          benefitIcon
          benefitTitle
        }
      }
      rtoCriteriaSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      ctaSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
      }
      rentalPartnerSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        childrenContentfulSectionFeatureDetailsJsonNode {
          productName
          productImage
        }
      }
      barnBonusSection {
        labelText
        subHeading
        headingText
        contentText {
          contentText
        }
        linkText
        linkUrl
        buttonText
        buttonUrl
      }
      dealerSection {
        labelText
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        linkText
        linkUrl
        buttonText
        buttonUrl
      }
      paymentOptionSection {
        labelText
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        linkText
        linkUrl
        childrenContentfulSectionFeatureDetailsJsonNode {
          paymentTitle
          paymentLink
        }
      }
      jagBarnSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          title
          fluid {
            src
            srcSet
          }
        }
        sectionImage {
          title
          fluid {
            src
            srcSet
          }
        }
      }
      quickContactSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        childContentfulSectionFeatureDetailsJsonNode {
          conditionText
        }
      }
    }
    contentfulAdBanner(active: { eq: true }) {
      backgroundImage {
        title
        fluid(quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      headingText
      image {
        title
        fluid(quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      linkText
      linkUrl
    }
    allContentfulMonthlyCalculatorValues(filter: { active: { eq: true } }) {
      nodes {
        option1NumberOfMonths
        option1Divisor
        option2NumberOfMonths
        option2Divisor
        option3NumberOfMonths
        option3Divisor
        option4NumberOfMonths
        option4Divisor
      }
    }
  }
`;
