import React from 'react'
import styled, { keyframes } from 'styled-components'
import BreakpointDown from '../Media/BreakpointDown'
import { ScrollIcon } from '../Icons'


const Pulse = keyframes`
  0% {
    transform: rotate(90deg) translate(0, 0);
    transform: rotate(90deg) translate(0, 0); }
  50% {
    transform: rotate(90deg) translate(0, 10px);
    transform: rotate(90deg) translate(0, 10px); }
  100% {
    transform: rotate(90deg) translate(0, 0);
    transform: rotate(90deg) translate(0, 0); }
` 


const ScrollDownWrapper = styled.div`
 position:absolute;
 top:80%;
 transform:translateY(-50%);
 display:flex;
 left:0;
 z-index:2;
 margin-left: -90px;
 transform: rotate(-90deg);
 align-items:center;
 flex-direction: row-reverse;
 transform-origin:left;
 cursor:pointer;

 span{
    color:#121316;
    text-transform: uppercase;
    letter-spacing: 3.64px;
    font-size:14px;
    font-family: 'Poppins', sans-serif;
    font-weight:600;
 }
 svg{
    transform: translate(0, 0) rotate(90deg);
    margin-right: 24px;
    animation: ${Pulse} 2s infinite;
    position:relative;
    top:0;
 }
 @media(max-width:1440px){
  margin-left: 50px;
  top: 92%;
 }
 @media(min-width:1500px) and (max-width:1800px){
  margin-left: -70px;
 }
 @media(max-width:1366px){
  margin-left: 40px;
  top: 92%;
 }
 ${BreakpointDown.xl`
    display:none;
 `}
`

const ScrollDown = ({ title }) => {
 const ScrollAnim = () => (
    document.querySelector("#who-we-are").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
 )
  return(
    <ScrollDownWrapper onClick={ScrollAnim}>
      <span>{title}</span>
      <ScrollIcon />  
    </ScrollDownWrapper>
  )
}

export default ScrollDown;